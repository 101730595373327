.out-column {
    width: 150px; /* Adjust as needed */
}

.date-column {
    width: 120px; /* Adjust as needed */
}

.summary-cell-border {
    border: 2px solid #ccc;
  }
  