.login_page {
    // background: #f6f5f4 !important;
    min-height: 100vh;
    width: 100%;
    // padding-top: 8rem;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label {
        font-family: 'Roboto', sans-serif !important;
    }

    .login_page_form {
        width: 40%;
        // background-color: #fff;
        color: #fff;
        // padding: 3rem 2rem;
        margin: auto;
        border-radius: 10px;
        // box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        height: 100vh;

        & img {
            width: 100px;
            height: 100px;
            align-self: center;
        }

        & h2 {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 32px;
            font-weight: 600;
        }

        .form_btn {
            background-color: #161ed7;
            // padding: 0.8rem;
            // font-size: 20px;
        }

        // .form-control{
        //     padding: 0.5rem 1rem;
        // }

        .icon-btn {
            cursor: pointer;
            border-radius: 6px !important;
            background-color: #fff;
        }

        .catpcha{
            margin: 1rem 0;
            display: flex;
            justify-content: center;
            & h6{
                font-size: 20px;
                font-weight: 500;
                background-color: grey;
                width: fit-content;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                color: #fff;
            }
        }

        .project-name{
            margin: 1rem 0;
            display: flex;
            justify-content: center;
            & h6{
                font-size: 16px;
                font-weight: 500;
                background-color: white;
                width: fit-content;
                padding: 0.2rem 1rem;
                border-radius: 5px;
                font-weight: 600;
            }
        }


        .login-btn{
            margin: 1rem 0;
            display: flex;
            justify-content: center;
            & .form_btn{
                width: 170px;
                border-radius: 25px;
                color: #fff;
                border: 0;
                padding: 0.5rem 1rem;
            }
        }
    }
}


@media (max-width:768px) {
    .login_page {
        // padding-top: 4rem;

        .login_page_form {
            width: 60%;
        }
    }
}

@media (max-width:500px) {
    .login_page {
        // padding-top: 2rem;

        .login_page_form {
            width: 100%;
        }
    }
}