html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
  scroll-behavior: smooth;
}

code {
  font-family: 'Roboto', sans-serif !important;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 95% !important;
}

// for font smoothing
.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif !important;
}

.h2,
h2 {
  font-size: 31px;
}

.admin_padding {
  padding: 2.5rem 2rem;
}

.page_add_btn {
  background-color: rgb(0, 0, 0);
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;


  &:hover {
    color: #fff;
  }
}

.table_action_btn {
  background-color: rgb(44, 26, 187);
  padding: 2px 4px;
  color: #fff;
  box-shadow: 1px 1px 4px #787676;
  margin-right: 10px;
  cursor: pointer;
}


// for form control
.form-control {
  border-radius: 6px !important;
  // line-height:0 !important;
  // padding: 0.15rem .75rem !important;
  // padding: 10px !important;
}

.input_file_button {
  background-color: #7a2ec0 !important;
  font-size: 16px !important;
  // padding: 10px 86px !important;
  border-radius: 5PX !important;
  color: #fff !important;
  border: none !important;
}

.admin_padding_table {
  background-color: #fff;
  width: 98%;
  margin: 2px auto;
  padding: 30px 40px;
  border-radius: 10px;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}

.admin_add_form {
  // background-color: #fff;
  // width: 98%;
  // margin: 2px auto;
  padding: 0 30px 40px 40px;
  // border-radius: 10px;
  // box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}

.admin_form {
  background-color: #fff;
  width: 100%;
  margin: 2px auto;
  // padding: 30px 40px;
  border-radius: 10px;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}

.form-select {
  padding: 0.5rem 1rem;
}

.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.search_input {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 300px;
}


@media (max-width:768px) {
  .table-hader-name {
    font-size: 18px !important;
  }

  .footer-btns {
    // flex-direction: column;
    gap: 10px
  }
}

@media (max-width:525px) {
  .table-hader-container {
    flex-direction: column;
    align-items: flex-start !important;
    margin-top: 10px;

    .table-hader-name {
      font-size: 16px !important;
    }

    .page_add_btn {
      font-size: 14px !important;
      padding: 8px !important;
    }
  }

  .search_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  .search_input {
    width: 100%;
  }

  .input_file_button {
    font-size: 14px !important;
    padding: 10px 20px !important;
  }

  .admin_add_form {
    padding: 0 20px 20px 20px;
  }

  .admin_padding_table {
    padding: 20px;
  }
}

.report-pass-btn {
  background-color: "green" !important;
}

.transaction-pass-btn {
  background-color: "red" !important;
}

.ant-pagination-item  {
  display: flex !important;
  justify-content: center;
}

