// .ant-table {
//     border: 2px solid #ccc;  /* Sets border around the entire table */
//   }
  
  .ant-table-thead > tr > th, 
  .ant-table-tbody > tr > td {
    border: 2px solid #ccc;  /* Sets border for each cell */
  }

  .ant-table-summary > tr > th, 
  .ant-table-summary > tr > td {
    border: 2px solid #ccc;  /* Sets border for each cell */
  }