// #dashboard {
//     // background: #f6f5f4 !important
//     background: #ffffff !important;
//     min-height: 100vh;
//     margin-bottom: 5px;
//   p, h1, h2, h3, h4, h5, h6, label{
//     font-family: 'Roboto', sans-serif !important;
//   }
// }

#box {
  font-family: 'Roboto', sans-serif !important;
  width: 80%;
  max-width: 500px;
  margin-top: 20px;
  margin-left: 20px;
  height: 300px;
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: center;
  background-color: #efefef;
}


#dashboard {

  .dashboard-breadcumb {
    margin-bottom: 30px;
    margin-top: 30px;

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  .dashboard-card {
    margin-bottom: 5px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    // background-color: #efefef;
    text-align: left;

    // background: linear-gradient(135deg, #00aaff, #0044cc);
    // background: linear-gradient(135deg, rgba(0, 183, 255, 0.8), rgba(255, 95, 87, 0.8));
    .card-body {
      padding: 0;
    }

    .card-title {
      font-size: 0.8rem;
      font-weight: 700;
      // margin-bottom: 1rem; 
    }

    .card-text {
      font-size: 1.5rem; // Increase font size of the description
      font-weight: 700;
    }
  }

  .first-card {
    background: linear-gradient(#00aaff 0%, #4d70b7 100%);
  }

  .second-card {
    background: linear-gradient(#ff5f57 0%, #f94a4a 100%);
  }

  .third-card {
    background: linear-gradient(#ffcc00 0%, #ff9900 100%);
  }



  // .dashboard-card:hover {
  //   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  //   transform: translateY(-2px); /* Slight lift effect on hover */
  //   transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
  // }


  .d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Add some space between cards */
    // background: #ffffff !important;
    // min-height: 100vh;
    margin-bottom: 5px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label {
      font-family: 'Roboto', sans-serif !important;
    }
  }

  .dashboard-card {
    flex: 1 1 calc(33.333% - 10px);
    /* Three cards per row with space between */
    max-width: calc(33.333% - 10px);
    // flex: 1 1 100%;
    // max-width: 100%;

    box-sizing: border-box;
    /* Ensure padding and border are included in width */
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .dashboard-card {
      flex: 1 1 calc(50% - 10px);
      // flex: 1 1 100%;
      // 
      /* Two cards per row on medium screens */
      max-width: calc(50% - 10px);
      // max-width: 100%;

    }
  }

  @media (max-width: 768px) {
    .dashboard-card {
      flex: 1 1 100%;
      /* One card per row on small screens */
      max-width: 100%;
    }

    .dashboard-breadcumb {
      h1 {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }

  }
}