.sidebar {
    // height: calc(100vh - 66px);
    height: 100vh;
    // background-color: #000c17;
    position: fixed;
    top: 0px;
    z-index: 999;
}

.ant-layout {
    background: #fbfbfb !important;
}

.site-layout {
    margin-left: 240px;
}

.ant-layout-sider {
    background: #fff !important;
    // background: #af883f !important;
    box-shadow: 1px 1px 1px #9b9a9a;
}

.ant-layout-header {
    background: none !important;
}

.ant-menu {
    background: #fff !important;
}

.ant-layout-footer {
    background: #f9fbfe !important;
}

.sidebar-NX {
    width: 48px;
}

.sidebarWrapper {
    padding: 20px 0px;
    color: #212529;
    position: relative;
}

.sidebarWrapperBtn {
    z-index: 999;
    background: none;
    border: none;
    cursor: pointer;
    margin: auto -10px;
    border-radius: 0px 20px 20px 20px;
    height: 55px;
    width: 55px;
}

.sidebarWrapperButton {
    position: absolute;
    right: -60px;
    z-index: 999;
    top: 5px;
    //   bottom: -40px;
    background: none;
    border: none;
    cursor: pointer;
    margin: auto -10px;
    //   background-color: #0d6efd;
    //   border: 2px solid #3c4045;
    //   padding: 12px;
    border-radius: 0px 20px 20px 20px;
    height: 55px;
    width: 55px;
}

.sidebarWrapperButton span {
    display: block;
    margin-top: 4px;
    background-color: rgb(37, 34, 34);
    border-radius: 15px;
    height: 2px;
    width: 22px;
    transition: 0.4s;
}

.sidebarWrapperBtn span {
    display: block;
    margin-top: 4px;
    background-color: rgb(37, 34, 34);
    border-radius: 15px;
    height: 4px;
    width: 26px;
    transition: 0.4s;
}

.sidebarMenu {
    margin-bottom: 6px;
}

.sidebarTitle {
    font-size: 14px;
    font-weight: 500;
    color: rgb(245, 244, 244);
    background-color: #0d3d84;
    padding: 10px;
    display: flex;
    align-items: center;
}

.sidebarList {
    list-style: none;
    /* padding: 5px 0px 5px 20px; */
}

.sidebarListItem {
    padding: 5px;
    cursor: pointer;
}

.sidebarListItem-NX {
    margin: auto;
}

.sidebarListItem a {
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.sidebarListItem.active,
.sidebarListItem:hover {
    background-color: rgb(13, 61, 132);
}

.sidebarLogo {
    margin-bottom: 0.6rem;
    margin-top: 1rem;
    padding: 0.4rem;
    text-align: center;
    border-bottom: 1px solid #1a243b;

    & .collapsed-img {
        width: 40px;
        height: 40px;
    }

    & .uncollapsed-img {
        width: 100px;
        height: 100px;
    }

    & h4 {
        font-size: 18px;
    }
}

// topbar scss
.topbar {
    width: 100%;
    height: 66px;
    background-color: #ffffffe6;
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: 0 0 10px rgba(27, 27, 27, 0.08);
}

.topbarWrapper {
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .topRight {
        display: flex;
        align-items: center;

        .topbar-text {
            margin-right: 0.3rem;
            margin-top: 0.8rem;
        }
    }
}

.admin-logo img {
    width: 200px;
}

.admin-avatar {
    color: rgba(0, 0, 0, 0.85) !important;
    border-radius: 50%;
    // display: flex;
    background: #e3e3e3 !important;
    width: 2.6rem !important;
    height: 2.6rem !important;
    cursor: pointer;
}


// for antd sidebar menu
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #29385a !important;
    // width: 200px;
    border-radius: 20px;
    // margin-left: 16px;
    box-shadow: 1px 1px 10px #1a253d;

}

.ant-menu-title-content {
    font-size: 12px !important;
}

vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0px !important;
}

// for admin page button

.admin-page-button {
    background-color: #28c76f !important;
    padding: 10px 20px;
    // padding: 4px 20px;
    width: auto;
    float: right;
    border-radius: 4px;
    border: 1px solid #28c76f;
    color: #ffffff !important;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.admin-page-button:hover {
    border: 1px solid #28c76f;
    color: #379231 !important;
    background-color: #ffffff !important;
    box-shadow: 0 10px 30px 0 rgb(182 182 182 / 27%);
}

.admin-topbar-btn {
    border: none !important;
    // margin: 0.3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-layout-header {
    padding: 0 !important;
}


@media (max-width: 768px) {
    .topbarWrapper {
        justify-content: end;
    }

    .site-layout {
        margin-left: 0;
    }
}


.close-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 24px;
    border: none;
    cursor: pointer;
    height: 55px;
    // width: 55px;
}

.close-button .close-icon {
    color: rgb(0, 0, 0);
    font-size: 24px;
}

body {
    overflow-x: hidden;
}

// :where(.css-dev-only-do-not-override-sk7ap8).ant-menu-inline>.ant-menu-item,
// :where(.css-dev-only-do-not-override-sk7ap8).ant-menu-vertical>.ant-menu-item,
// :where(.css-dev-only-do-not-override-sk7ap8).ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
// :where(.css-dev-only-do-not-override-sk7ap8).ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
//     height: 22px;
// }

// :where(.css-dev-only-do-not-override-sk7ap8).ant-menu-inline .ant-menu-item {
//     height: 22px;
// }

.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 22px;
}

.ant-menu-inline .ant-menu-item {
    height: 22px;
}

.ant-menu-submenu-title,
.ant-menu-item {
    margin-top: 2px !important;
    margin-bottom: 0 !important;
}

.ant-menu-light .ant-menu-item-selected{
    background-color: transparent;
}

// .ant-menu-item,
// .ant-menu-submenu {
//     height: 34px !important;
// }

// li .ant-menu-item{
//     height: 15px;
// }