#userLists {
    background: #f6f5f4 !important;
    min-height: calc(100vh - 70px);
  p, h1, h2, h3, h4, h5, h6, label{
    font-family: 'Roboto', sans-serif !important;
  }
}


#userView {
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    background: #ffffff !important;
  p, h1, h2, h3, h4, h5, h6, label{
    font-family: 'Roboto', sans-serif !important;
  }
  }
}

.card {
  background: #111827;
  color: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  margin-left: 20px;
  height: auto;
}

.card-body {
  padding: 20px;
}

.colon {
  // margin-right: 50px;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}


.label {
  display: inline-block; 
  width: 100px; 
  // margin-right: 25px;
  font-weight: bold;
}

.value {
  color: #e0e0e0;
}
.table-container {
  position: relative; 
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; 
  background: rgba(255, 255, 255, 0.8); 
  padding: 20px; 
}









#userView {

  .user-card {
    margin-bottom: 5px;
    padding: 13px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: #efefef;
    text-align: left;

    .card-body {
      padding: 0; 
    }

    .card-title {
      font-size: 13px;
      font-weight: 600;
      // margin-bottom: 1rem; 
    }

    .card-text {
      font-size: 1.5rem; // Increase font size of the description
      font-weight: 700;
    }
  }

  .d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Add some space between cards */
    background: #ffffff !important;
    // min-height: 100vh;
    margin-bottom: 5px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label {
      font-family: 'Roboto', sans-serif !important;
    }
  }

  .user-card {
    flex: 1 1 calc(25% - 10px);
    /* Three cards per row with space between */
    max-width: calc(33.333% - 10px);
    // flex: 1 1 100%;
    // max-width: 100%;

    box-sizing: border-box;
    /* Ensure padding and border are included in width */
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .user-card {
      flex: 1 1 calc(50% - 10px);
      // flex: 1 1 100%;
      // 
      /* Two cards per row on medium screens */
      max-width: calc(50% - 10px);
      // max-width: 100%;

    }
  }

  @media (max-width: 768px) {
    .user-card {
      flex: 1 1 100%;
      /* One card per row on small screens */
      max-width: 100%;
    }
  }
}

.user-card-back{
  background-color: #fbfbfb !important;
}

.first-card {
  background: linear-gradient(#00aaff 0%, #4d70b7 100%);
}

.second-card {
  background: linear-gradient(#ff5f57 0%, #f94a4a 100%);
}

.third-card {
  background: linear-gradient(#ffcc00 0%, #ff9900 100%);
}

.forth-card{
  background: linear-gradient( #8515ebe3 0%, #6a0dad 100%);
}