#subDestributorLists {
    background: #f6f5f4 !important;
    min-height: calc(100vh - 70px);
  p, h1, h2, h3, h4, h5, h6, label{
    font-family: 'Roboto', sans-serif !important;
  }
}


#subDestributorView {
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    background: #ffffff !important;
  p, h1, h2, h3, h4, h5, h6, label{
    font-family: 'Roboto', sans-serif !important;
  }
  }
}

.card {
  background: #111827;
  color: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  margin-left: 20px;
  height: auto;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}


.label {
  display: inline-block; 
  width: 100px; 
  // margin-right: 25px;
  font-weight: bold;
}

.value {
  color: #e0e0e0;
}

.colon {
  // margin-right: 50px;
}
.table-container {
  position: relative; 
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; 
  background: rgba(255, 255, 255, 0.8); 
  padding: 20px; 
}