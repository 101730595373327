// #registerLists {
//     background: #f6f5f4 !important;
//     min-height: calc(100vh - 70px);
//   p, h1, h2, h3, h4, h5, h6, label{
//     font-family: 'Roboto', sans-serif !important;
//   }
// }





// #registerView {
//   .d-flex {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 10px; 
//     background: #ffffff !important;
//   p, h1, h2, h3, h4, h5, h6, label{
//     font-family: 'Roboto', sans-serif !important;
//   }
//   }
// }


// .card {
//   background: #111827;
//   color: #fff;
//   width: 100%;
//   max-width: 500px;
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   min-height: 400px;
//   margin-left: 20px;
//   height: auto;
// }

// .card-body {
//   padding: 20px;
// }

// .card-title {
//   font-size: 1.5rem;
//   margin-bottom: 10px;
// }


// .label {
//   display: inline-block; 
//   width: 100px; 
//   margin-right: 25px;
//   font-weight: bold;
// }
// .colon {
//   margin-right: 50px;
// }

// .value {
//   color: #e0e0e0;
// }


// .table-container {
//   position: relative; 
// }

// .spinner-overlay {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 1000; 
//   background: rgba(255, 255, 255, 0.8); 
//   padding: 20px; 
// }

// .detail-heading{
//   margin: 1rem 0;
//   & h6{
//       font-size: 17px;
//       font-weight: 300;
//       background-color: #04070c;
//       width: 100%;
//       padding: 0.5rem 1rem;
//       border-radius: 5px 5px 0 0;
//       color: #fff;
//   }
// }



#registerLists {
  background: #f6f5f4 !important;
  min-height: calc(100vh - 70px);
p, h1, h2, h3, h4, h5, h6, label{
  font-family: 'Roboto', sans-serif !important;
}
}


#registerView {
.d-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  background: #ffffff !important;
p, h1, h2, h3, h4, h5, h6, label{
  font-family: 'Roboto', sans-serif !important;
}
}
}


.card {
// background: #111827;
// color: #fff;
background: #fff;
color: #111827;
width: 100%;
max-width: 500px;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
min-height: 400px;
margin-left: 20px;
height: auto;
p{
 margin-bottom: 0px !important;
 border: 2px solid;
}
}

.card-body {
padding: 20px;
}

.card-title {
font-size: 1.5rem;
margin-bottom: 10px;
}


.label {
display: inline-block; 
width: 150px; 
// padding-right: 25px;
font-weight: bold;
// border: 2px solid;
padding-left: 10px;
padding-top: 10px;
padding-bottom: 10px;
}
.colon {
  padding-right: 50px;
// border: 2px solid;

}

.value {
color: #111827;
width: 250px; 
padding-right: 25px;
display :inline-block;
font-weight: bold;
// border: 2px solid;
padding-left: 10px;
padding-top: 10px;
padding-bottom: 13px;

}


.table-container {
position: relative; 
}

.spinner-overlay {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 1000; 
background: rgba(255, 255, 255, 0.8); 
padding: 20px; 
}

.detail-heading{
// margin: 1rem 0;
& h6{
    font-size: 17px;
    font-weight: 300;
    background-color: #04070c;
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 5px 5px 0 0;
    color: #fff;
}
}


@media (max-width: 768px) {
  .label {
    width: 70px; 
    }
    .colon {
      padding-right: 15px;    
    }

    // .value {
    //   width: 100px; 
    //   display :inline-block
    //   }
}

@media (max-width: 420px) {
    .value {
      width: 120px; 
      display :inline-block
      }
}
