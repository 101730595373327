a {
    color: #007aff;
}

.app {
    padding-top: 2.875rem;
}

 .card-header.card-header-inverse {
    background: #1a1a1a;
    color: #fff;
}

body {
    font-size: .8125rem;
    line-height: 1.42857;
    color: #000;
}

.label-gameresult {
   border-top-left-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
}
  
.label-gameresult-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
 }

* {
    text-align: left;
    margin: 0;
    padding: 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -7.5px;
    margin-left: -7.5px;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #d9d9d9;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

table {
    border-collapse: collapse;
}

// .card .card-body {
//     flex: 1 1 auto;
// }

// .card {
//     position: relative;
//     min-width: 0;
//     // max-width: 100%;
//     word-wrap: break-word;
//     background-color: #fff;
//     background-clip: border-box;
//     border: 1px solid rgba(0, 0, 0, .125);
//     border-radius: 6px;
// }

// .card, .card .card-header {
//     display: flex;
//     border: 0;
// }

.game-card {
    border: none;
    box-shadow: none;
}

@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

#Roulette_Wrap {
    float: left;
    height: 391px;
    width: 900px;
    background-image: url(../../../public/asset/game.jpeg);
    background-repeat: no-repeat;
    margin: 10px;
}

#Roulette_Neighbour_Wrap {
    float: left;
    height: 200px;
    width: 915px;
    background-image: url(../../../public/asset/gameresult.jpeg);
    background-repeat: no-repeat;
    margin: 12px 10px 10px 10px;
}

.spot0 {
    position: absolute;
    top: 496px;
    display: block;
    z-index: 5;
    left: 51px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot1 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 115px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot2 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 115px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot3 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 116px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot4 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 180px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot5 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 180px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot6 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 180px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot7 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 240px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot8 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 242px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot9 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 243px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot10 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 303px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot11 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 302px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot12 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 302px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot13 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 365px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot14 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 367px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot15 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 365px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot16 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 428px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot17 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 427px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot18 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 428px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot19 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 489px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot20 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 489px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot21 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 489px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot22 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 550px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.spot23 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 551px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.spot24 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 553px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot25 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 617px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot26 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 607px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot27 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 614px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot28 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 678px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot29 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 678px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spot30 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 678px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.spot31 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 738px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.spot32 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 738px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.spot33 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 740px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.spot34 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 803px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.spot35 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 803px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.spot36 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 803px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round0 {
    position: absolute;
    top: 913px;
    display: block;
    z-index: 5;
    left: 866px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round1 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 260px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round2 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 557px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round3 {
    position: absolute;
    top: 815px;
    display: block;
    z-index: 5;
    left: 855px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round4 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 657px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round5 {
    position: absolute;
    top: 808px;
    display: block;
    z-index: 5;
    left: 67px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round6 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 357px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round7 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 660px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round8 {
    position: absolute;
    top: 926px;
    display: block;
    z-index: 5;
    left: 67px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round9 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 460px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round10 {
    position: absolute;
    top: 845px;
    display: block;
    z-index: 5;
    left: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round11 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 157px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.round12 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 760px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round13 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 257px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round14 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 360px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round15 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 757px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round16 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 160px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round17 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 457px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round18 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 560px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round19 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 707px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round20 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 310px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round21 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 607px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round22 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 510px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round23 {
    position: absolute;
    top: 895px;
    display: block;
    z-index: 5;
    left: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round24 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 110px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round25 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 507px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round26 {
    position: absolute;
    top: 861px;
    display: block;
    z-index: 5;
    left: 895px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round27 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 307px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round28 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 710px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round29 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 610px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round30 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 107px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round31 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 410px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round32 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 807px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round33 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 210px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round34 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 407px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round35 {
    position: absolute;
    top: 801px;
    display: block;
    z-index: 5;
    left: 803px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round36 {
    position: absolute;
    top: 933px;
    display: block;
    z-index: 5;
    left: 207px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}
.spotw0 {
    position: absolute;
    top: 496px;
    display: block;
    z-index: 5;
    left: 51px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw1 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 115px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw2 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 115px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw3 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 115px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw4 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 180px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw5 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 180px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw6 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 180px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw7 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 240px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw8 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 240px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw9 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 240px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw10 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 302px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw11 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 302px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw12 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 302px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw13 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 365px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw14 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 365px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw15 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 365px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw16 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 427px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw17 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 427px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.spotw18 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 427px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw19 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 489px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw20 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 489px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw21 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 489px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw22 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 550px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw23 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 550px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw24 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 550px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw25 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 617px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw26 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 617px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw27 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 617px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw28 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 678px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw29 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 678px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw30 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 678px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.spotw31 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 738px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw32 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 738px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw33 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 738px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw34 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 803px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.spotw35 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 803px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.spotw36 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 803px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #3300FF;
    padding: 1px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.c1 {
    position: absolute;
    top: 579px;
    display: block;
    z-index: 5;
    left: 866px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.c2 {
    position: absolute;
    top: 493px;
    display: block;
    z-index: 5;
    left: 866px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.c3 {
    position: absolute;
    top: 405px;
    display: block;
    z-index: 5;
    left: 866px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.first-twelve {
    position: absolute;
    top: 680px;
    display: block;
    z-index: 5;
    left: 165px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.second-twelve {
    position: absolute;
    top: 680px;
    display: block;
    z-index: 5;
    left: 405px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.third-twelve {
    position: absolute;
    top: 680px;
    display: block;
    z-index: 5;
    left: 662px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.one-eighteen {
    position: absolute;
    top: 725px;
    display: block;
    z-index: 5;
    left: 105px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.even {
    position: absolute;
    top: 725px;
    display: block;
    z-index: 5;
    left: 228px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.red {
    position: absolute;
    top: 725px;
    display: block;
    z-index: 5;
    left: 397px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.black {
    position: absolute;
    top: 725px;
    display: block;
    z-index: 5;
    left: 522px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.odd {
    position: absolute;
    top: 725px;
    display: block;
    z-index: 5;
    left: 611px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.twenteen-thirtysix {
    position: absolute;
    top: 725px;
    display: block;
    z-index: 5;
    left: 724px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.tiers {
    position: absolute;
    top: 860px;
    display: block;
    z-index: 5;
    left: 400px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.orphelines {
    position: absolute;
    top: 860px;
    display: block;
    z-index: 5;
    left: 200px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.voisins {
    position: absolute;
    top: 860px;
    display: block;
    z-index: 5;
    left: 600px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.zero-spile {
    position: absolute;
    top: 860px;
    display: block;
    z-index: 5;
    left: 776px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

@media (max-width :'768px') {

    .spot0 {
        position: absolute;
        top: 675px;
        display: block;
        z-index: 5;
        left: 51px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot1 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 115px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot2 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 115px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot3 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 116px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot4 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 180px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot5 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 180px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot6 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 180px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot7 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 240px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot8 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 242px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot9 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 243px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot10 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 303px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot11 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 302px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot12 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 302px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot13 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 365px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot14 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 367px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot15 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 365px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot16 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 428px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot17 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 427px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot18 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 428px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot19 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 489px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot20 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 489px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot21 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 489px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot22 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 550px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }



    .spot23 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 551px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .spot24 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 553px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot25 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 617px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot26 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 607px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot27 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 614px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot28 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 678px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot29 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 678px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spot30 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 678px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }



    .spot31 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 738px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .spot32 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 738px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .spot33 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 740px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .spot34 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 803px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }



    .spot35 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 803px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .spot36 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 803px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    
.round0 {
    position: absolute;
    top: 913px;
    display: block;
    z-index: 5;
    left: 866px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round0 {
    position: absolute;
    top: 1095px;
    display: block;
    z-index: 5;
    left: 866px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round1 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 260px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round2 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 557px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round3 {
    position: absolute;
    top: 997px;
    display: block;
    z-index: 5;
    left: 855px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round4 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 657px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round5 {
    position: absolute;
    top: 990px;
    display: block;
    z-index: 5;
    left: 67px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round6 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 357px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round7 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 660px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round8 {
    position: absolute;
    top: 1108px;
    display: block;
    z-index: 5;
    left: 67px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round9 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 460px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round10 {
    position: absolute;
    top: 1027px;
    display: block;
    z-index: 5;
    left: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round11 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 157px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}



.round12 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 760px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round13 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 257px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round14 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 360px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round15 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 757px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round16 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 160px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round17 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 457px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round18 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 560px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round19 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 707px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round20 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 310px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round21 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 607px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round22 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 510px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round23 {
    position: absolute;
    top: 1077px;
    display: block;
    z-index: 5;
    left: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round24 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 110px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round25 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 507px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round26 {
    position: absolute;
    top: 1043px;
    display: block;
    z-index: 5;
    left: 895px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round27 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 307px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round28 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 710px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round29 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 610px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round30 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 107px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round31 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 410px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round32 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 807px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round33 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 210px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round34 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 407px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round35 {
    position: absolute;
    top: 983px;
    display: block;
    z-index: 5;
    left: 803px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.round36 {
    position: absolute;
    top: 1115px;
    display: block;
    z-index: 5;
    left: 207px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

    .spotw0 {
        position: absolute;
        top: 673px;
        display: block;
        z-index: 5;
        left: 51px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw1 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 115px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw2 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 115px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw3 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 115px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw4 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 180px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw5 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 180px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw6 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 180px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw7 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 240px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw8 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 240px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw9 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 240px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw10 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 302px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw11 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 302px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw12 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 302px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw13 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 365px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw14 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 365px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw15 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 365px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw16 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 427px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw17 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 427px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .spotw18 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 427px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw19 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 489px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw20 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 489px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw21 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 489px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw22 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 550px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw23 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 550px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw24 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 550px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw25 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 617px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw26 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 617px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw27 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 617px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw28 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 678px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw29 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 678px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw30 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 678px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }



    .spotw31 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 738px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw32 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 738px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw33 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 738px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw34 {
        position: absolute;
        top: 757px;
        display: block;
        z-index: 5;
        left: 803px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .spotw35 {
        position: absolute;
        top: 670px;
        display: block;
        z-index: 5;
        left: 803px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }



    .spotw36 {
        position: absolute;
        top: 584px;
        display: block;
        z-index: 5;
        left: 803px;
        color: #FFFFFF;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #3300FF;
        padding: 1px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .c1 {
        position: absolute;
        top: 760px;
        display: block;
        z-index: 5;
        left: 866px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .c2 {
        position: absolute;
        top: 673px;
        display: block;
        z-index: 5;
        left: 866px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .c3 {
        position: absolute;
        top: 586px;
        display: block;
        z-index: 5;
        left: 866px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .first-twelve {
        position: absolute;
        top: 860px;
        display: block;
        z-index: 5;
        left: 165px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .second-twelve {
        position: absolute;
        top: 860px;
        display: block;
        z-index: 5;
        left: 405px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .third-twelve {
        position: absolute;
        top: 860px;
        display: block;
        z-index: 5;
        left: 662px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .one-eighteen {
        position: absolute;
        top: 906px;
        display: block;
        z-index: 5;
        left: 105px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .even {
        position: absolute;
        top: 906px;
        display: block;
        z-index: 5;
        left: 228px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .red {
        position: absolute;
        top: 906px;
        display: block;
        z-index: 5;
        left: 397px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .black {
        position: absolute;
        top: 906px;
        display: block;
        z-index: 5;
        left: 522px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .odd {
        position: absolute;
        top: 906px;
        display: block;
        z-index: 5;
        left: 611px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .twenteen-thirtysix {
        position: absolute;
        top: 906px;
        display: block;
        z-index: 5;
        left: 724px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .tiers {
        position: absolute;
        top: 1041px;
        display: block;
        z-index: 5;
        left: 400px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .orphelines {
        position: absolute;
        top: 1041px;
        display: block;
        z-index: 5;
        left: 200px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .voisins {
        position: absolute;
        top: 1041px;
        display: block;
        z-index: 5;
        left: 600px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .zero-spile {
        position: absolute;
        top: 1041px;
        display: block;
        z-index: 5;
        left: 776px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
}



// .game-result-container {
//     padding: 15px;
//     text-align: center;

//     .image-container {
//         display: flex;
//         justify-content: center;
//         margin-bottom: 20px;
//     }

//     .game-image {
//         width: 300px;
//         margin: 0 10px;
//     }
// }

// #Roulette_Wrap {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     position: relative;
//     width: 100%;
//     max-width: 800px;
//     margin: 0 auto;
// }

// .spot {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 25px;
//     height: 25px;
//     background-color: green;
//     margin: 5px;
//     border-radius: 5px;
//     position: relative;
//     transition: transform 0.3s;

//     &:hover {
//         transform: scale(1.1);
//     }

//     .aroulet {
//         color: white;
//         text-decoration: none;
//     }
// }

// .spotw9 {
//     width: 100%;
//     text-align: center;
//     font-weight: bold;
//     margin-top: 20px;
//     color: gold;
//     font-size: 1.5rem;
// }





.split-one-two {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 115px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-one-four {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 145px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-two-three {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 115px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-two-five {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 145px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thrre-six {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 145px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-six-nine {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 209px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-five-four {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 175px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-six-five {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 175px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-five-eight {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 209px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-four-seven {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 209px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seven-eight {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 240px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seven-ten {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 271px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seven-eight {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 240px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eight-nine {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 240px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eight-eleven {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 271px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-nine-twelev {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 271px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twelev-fifteen {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 333px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twelev-eleven {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 300px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eleven-fouteen {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 333px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eleven-ten {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 300px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-ten-thirteen {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 333px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirteen-fourteen {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 365px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirteen-sixteen {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 396px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-sixteen-seventeen {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 425px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-sixteen-nineteen {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 458px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seventeen-eightteen {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 425px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-fifteenteen-eightteen {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 396px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seventeen-twenty {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 458px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-fourtteen-fifteen {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 364px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-fourtteen-seventeen {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 396px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-fifteen-eightteen {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 396px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eightteen-twentyone {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 458px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyone-twenty {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 487px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyone-twentyfour {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 520px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twenty-twentythree {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 520px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twenty-nineteen {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 487px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-nineteen-twentytwo {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 520px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentytwo-twentythree {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 550px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentytwo-twentyfive {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 580px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentythree-twentyfour {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 550px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentythree-twentysix {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 580px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.split-twentyfour-twentyseven {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 580px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyseven-twentysix {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 613px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyseven-thirty {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 645px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentysix-twentyfive {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 613px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentysix-twentynine {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 645px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyfive-twentyeight {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 645px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyeight-twentynine {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 673px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyeight-thirtyone {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 705px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentynine-thirty {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 673px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentynine-thirtytwo {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 705px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirty-thirtythree {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 705px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtythree-thirtysix {
    position: absolute;
    top: 432px;
    display: block;
    z-index: 5;
    left: 769px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtythree-thirtytwo {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 737px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtytwo-thirtyone {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 737px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtytwo-thirtyfive {
    position: absolute;
    top: 518px;
    display: block;
    z-index: 5;
    left: 769px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtyone-thirtyfour {
    position: absolute;
    top: 600px;
    display: block;
    z-index: 5;
    left: 769px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtyfive-thirtysix {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 800px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtyfour-thirtyfive {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 800px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


@media(max-width:'768px') {


    .split-one-two {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 115px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-one-four {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 145px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-two-three {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 115px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-two-five {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 145px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thrre-six {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 145px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-six-nine {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 209px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-five-four {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 175px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-six-five {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 175px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-five-eight {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 209px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-four-seven {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 209px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-seven-eight {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 240px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-seven-ten {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 271px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-seven-eight {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 240px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-eight-nine {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 240px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-eight-eleven {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 271px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-nine-twelev {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 271px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twelev-fifteen {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 333px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twelev-eleven {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 300px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-eleven-fouteen {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 333px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-eleven-ten {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 300px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-ten-thirteen {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 333px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirteen-fourteen {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 365px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirteen-sixteen {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 396px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-sixteen-seventeen {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 425px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-sixteen-nineteen {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 458px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-seventeen-eightteen {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 425px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-fifteenteen-eightteen {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 396px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-seventeen-twenty {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 458px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-fourtteen-fifteen {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 364px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-fourtteen-seventeen {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 396px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-fifteen-eightteen {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 396px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-eightteen-twentyone {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 458px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyone-twenty {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 487px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyone-twentyfour {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 520px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twenty-twentythree {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 520px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twenty-nineteen {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 487px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-nineteen-twentytwo {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 520px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentytwo-twentythree {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 550px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentytwo-twentyfive {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 580px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentythree-twentyfour {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 550px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentythree-twentysix {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 580px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }


    .split-twentyfour-twentyseven {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 580px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyseven-twentysix {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 613px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyseven-thirty {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 645px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentysix-twentyfive {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 613px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentysix-twentynine {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 645px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyfive-twentyeight {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 645px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyeight-twentynine {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 673px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentyeight-thirtyone {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 705px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentynine-thirty {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 673px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-twentynine-thirtytwo {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 705px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirty-thirtythree {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 705px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtythree-thirtysix {
        position: absolute;
        top: 615px;
        display: block;
        z-index: 5;
        left: 769px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtythree-thirtytwo {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 737px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtytwo-thirtyone {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 737px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtytwo-thirtyfive {
        position: absolute;
        top: 702px;
        display: block;
        z-index: 5;
        left: 769px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtyone-thirtyfour {
        position: absolute;
        top: 784px;
        display: block;
        z-index: 5;
        left: 769px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtyfive-thirtysix {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 800px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

    .split-thirtyfour-thirtyfive {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 800px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }

}


.split-one-to-four {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 145px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-two-to-five {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 145px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-four-to-seven {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 209px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-five-to-eight {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 209px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seven-to-ten {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 271px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eight-to-eleven {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 271px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-ten-to-thirteen {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 333px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-eleven-to-fourteen {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 333px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirteen-to-sixteen {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 396px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-fourteen-to-seventeen {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 396px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-sixteen-to-nineteen {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 458px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-seventeen-to-twenty {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 458px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-nineteen-to-twentytwo {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 520px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twenty-to-twentythree {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 520px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentytwo-to-twentyfive {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 580px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentythree-to-twentysix {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 580px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentysix-to-twentynine {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 645px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}


.split-twentyfive-to-twentyeight {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 645px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentyeight-to-thirtyone {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 705px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-twentynine-to-thirtytwo {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 705px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtyone-to-thirtyfour {
    position: absolute;
    top: 563px;
    display: block;
    z-index: 5;
    left: 769px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

.split-thirtytwo-to-thirtyfive {
    position: absolute;
    top: 477px;
    display: block;
    z-index: 5;
    left: 769px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background-color: #00FF99;
    padding: 0px;
    border: 1px solid #333333;
    text-align: center;
    margin: 1px;
}

@media( max-width :'768px'){

    .table-margin{
        margin-bottom: 50px !important;
        margin-left: 10px !important;
    }


    .split-one-to-four {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 145px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-two-to-five {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 145px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-four-to-seven {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 209px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-five-to-eight {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 209px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-seven-to-ten {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 271px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-eight-to-eleven {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 271px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-ten-to-thirteen {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 333px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-eleven-to-fourteen {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 333px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-thirteen-to-sixteen {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 396px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-fourteen-to-seventeen {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 396px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-sixteen-to-nineteen {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 458px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-seventeen-to-twenty {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 458px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-nineteen-to-twentytwo {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 520px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-twenty-to-twentythree {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 520px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-twentytwo-to-twentyfive {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 580px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-twentythree-to-twentysix {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 580px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-twentysix-to-twentynine {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 645px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    
    .split-twentyfive-to-twentyeight {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 645px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-twentyeight-to-thirtyone {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 705px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-twentynine-to-thirtytwo {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 705px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-thirtyone-to-thirtyfour {
        position: absolute;
        top: 743px;
        display: block;
        z-index: 5;
        left: 769px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
    
    .split-thirtytwo-to-thirtyfive {
        position: absolute;
        top: 656px;
        display: block;
        z-index: 5;
        left: 769px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: #00FF99;
        padding: 0px;
        border: 1px solid #333333;
        text-align: center;
        margin: 1px;
    }
}


