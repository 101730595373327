#partnerLists {
  background: #f6f5f4 !important;
  min-height: calc(100vh - 70px);

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    font-family: 'Roboto', sans-serif !important;
  }
}


#partnerView {
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background: #ffffff !important;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label {
      font-family: 'Roboto', sans-serif !important;
    }
  }
}

.card {
  background: #111827;
  color: #fff;
  width: 100%;
  // max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  margin-left: 20px;
  height: auto;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}


.label {
  display: inline-block;
  width: 150px;
  // margin-right: 25px;
  font-weight: bold;
}

.colon {
  // margin-right: 50px;
}

.value {
  color: #e0e0e0;
}

.table-container {
  position: relative;
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
}


.custom-switch {
  .form-check-input {
    // background-color: blue !important; // Change this to your desired color
    border-color: black !important; // Change this to your desired color
    border: 1px solid;
  }

  // .form-check-input:focus {
  //   box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.25); // Adjust focus outline color
  // }
}

.card-body .table-bordered,
.table-bordered td,
.table-bordered th .label {
  min-width: 90px;
  border-left:none !important;
  border-bottom: none !important;
  border-top: none !important;
}

.table-bordered td:first-child {
  border-right:none !important;
}

.table-bordered td:last-child {
  border-left:1px solid #d9d9d9 !important;
}

.card-body .table-bordered,
.table-bordered tr {
  border-bottom: 2px solid #d9d9d9;
  border-top: 2px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}



// @media (max-width: 768px) {
//   .table-responsive {
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
//   }

//   table {
//     min-width: 100%;
//   }
// }

.add-point-btn{
  background-color: rgb(196 13 253) !important;
}

.sub-point-btn{
  background-color: rgb(243 77 58) !important;
}